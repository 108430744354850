var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px", "no-click-animation": "", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _vm._v("Are you sure you want to change this user's password?"),
          ]),
          _c(
            "v-card-text",
            { staticClass: "pt-5" },
            [
              _c(
                "v-form",
                { ref: "form", on: { submit: _vm.decline } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "Auto-generate password" },
                            model: {
                              value: _vm.autoGeneratePassword,
                              callback: function ($$v) {
                                _vm.autoGeneratePassword = $$v
                              },
                              expression: "autoGeneratePassword",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.autoGeneratePassword,
                              expression: "!autoGeneratePassword",
                            },
                          ],
                          attrs: { cols: "12", md: "12" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "New Password",
                              "append-icon": _vm.showNewPasswordText
                                ? "mdi-eye"
                                : "mdi-eye-off",
                              type: _vm.showNewPasswordText
                                ? "text"
                                : "password",
                            },
                            on: {
                              "click:append": function ($event) {
                                _vm.showNewPasswordText =
                                  !_vm.showNewPasswordText
                              },
                            },
                            model: {
                              value: _vm.newPassword,
                              callback: function ($$v) {
                                _vm.newPassword = $$v
                              },
                              expression: "newPassword",
                            },
                          }),
                          _c("password-strength", {
                            attrs: { password: _vm.newPassword },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(" No ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    loading: _vm.saving,
                    disabled:
                      _vm.saving ||
                      (!_vm.autoGeneratePassword && _vm.newPassword.length < 8),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.resetUserPassword()
                    },
                  },
                },
                [_vm._v(" Yes ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }