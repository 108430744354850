var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pl-0", attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.cancel(false)
                        },
                      },
                    },
                    [_vm._v(" cancel ")]
                  ),
                  _vm.canEditUser
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: { color: "green", dark: "" },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v(" save ")]
                      )
                    : _vm._e(),
                  _vm.canDeleteUser
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right mr-2",
                          attrs: { color: "red", dark: "" },
                          on: { click: _vm.onRemove },
                        },
                        [_vm._v(" remove ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c(
            "v-form",
            { attrs: { readonly: !_vm.canEditUser } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _vm.clientConfig.data.authentication !== "tam"
                    ? _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-banner",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.found === 0,
                                  expression: "found === 0",
                                },
                              ],
                              attrs: {
                                color: "amber lighten-5",
                                width: "100%",
                              },
                            },
                            [
                              _c("h5", { staticClass: "text-subtitle-2" }, [
                                _vm._v(
                                  " This user will be required to complete the registration form before they can access the application. "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-banner",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.foundEmail == 2,
                                  expression: "foundEmail == 2",
                                },
                              ],
                              attrs: {
                                color: "green lighten-5",
                                width: "100%",
                              },
                            },
                            [
                              _c("h5", { staticClass: "text-subtitle-2" }, [
                                _vm._v(
                                  " This email is already registered with an EZTransportation application. To complete registration to EZActivityTrips, they can simply log in using their existing credentials. "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-banner",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.foundEmail == 6,
                                  expression: "foundEmail == 6",
                                },
                              ],
                              attrs: { color: "red lighten-5", width: "100%" },
                            },
                            [
                              _c("h5", { staticClass: "text-subtitle-2" }, [
                                _vm._v(
                                  "This email is already registered with EZActivityTrips"
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-banner",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.foundPhone == 8,
                                  expression: "foundPhone == 8",
                                },
                              ],
                              attrs: {
                                color: "green lighten-5",
                                width: "100%",
                              },
                            },
                            [
                              _c("h5", { staticClass: "text-subtitle-2" }, [
                                _vm._v(
                                  " This phone number is already registered with us. Original password and full name will be used. You can change them after saving. "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-banner",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.foundPhone == 24,
                                  expression: "foundPhone == 24",
                                },
                              ],
                              attrs: { color: "red lighten-5", width: "100%" },
                            },
                            [
                              _c("h5", { staticClass: "text-subtitle-2" }, [
                                _vm._v(
                                  "This phone number is already registered with EZActivityTrips."
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    { staticClass: "mt-4", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.displayName,
                              label: "Display Name",
                              required: "",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.displayName
                              ),
                              disabled: !!_vm.value.id && !_vm.isAdmin,
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.displayName.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.displayName.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.displayName,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.displayName.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.firstName,
                              label: "First Name",
                              required: "",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.firstName
                              ),
                              disabled:
                                (_vm.found == 2 || !!_vm.value.id) &&
                                !_vm.isAdmin,
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.firstName.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.firstName.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.firstName,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.firstName.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.lastName,
                              label: "Last Name",
                              required: "",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.lastName
                              ),
                              disabled:
                                (_vm.found == 2 || !!_vm.value.id) &&
                                !_vm.isAdmin,
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.lastName.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.lastName.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.lastName,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.lastName.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.email,
                              label: "Email",
                              required: "",
                              "error-messages": _vm.handleErrors(
                                _vm.$v.form.email
                              ),
                              disabled: !!_vm.value.id,
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.email.$touch()
                              },
                              change: _vm.checkExist,
                            },
                            model: {
                              value: _vm.$v.form.email.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.email,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.email.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": !_vm.$v.$dirty
                                ? []
                                : _vm.$v.form.phone.minLength
                                ? []
                                : ["Phone number must be 10 digits"],
                              value: _vm.value.phone,
                              label: "Phone",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.phone.$touch()
                              },
                            },
                            model: {
                              value: _vm.form.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "phone", $$v)
                              },
                              expression: "form.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.canResetPassword
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _vm.value.uuid != "TEMP-NOT-REGISTERED"
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: { click: _vm.resetUserPassword },
                                    },
                                    [_vm._v(" Reset Password ")]
                                  )
                                : _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: { click: _vm.registerUser },
                                    },
                                    [_vm._v(" Check if Registered ")]
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "my-0 py-0", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.value.yellowfinId,
                              label: "Yellowfin ID",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.yellowfinId.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.yellowfinId.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.form.yellowfinId,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.yellowfinId.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.canEditUser &&
                      _vm.value.uuid != "TEMP-NOT-REGISTERED" &&
                      !_vm.usesRoster
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.impersonate(_vm.value)
                                    },
                                  },
                                },
                                [_vm._v(" Impersonate ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.value.uuid == "TEMP-NOT-REGISTERED" &&
                  _vm.clientConfig.data.authentication !== "tam"
                    ? _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-alert",
                                {
                                  attrs: {
                                    outlined: "",
                                    type: "warning",
                                    text: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " This user has not yet registered an account with this email address "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("h4", [_vm._v("Roles")]),
                          _c(
                            "v-container",
                            [
                              _c("v-simple-table", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function () {
                                      return [
                                        _c("thead", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { staticClass: "text-left" },
                                              [_vm._v("Role")]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "text-left" },
                                              [_vm._v("Location(s)")]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "text-left" },
                                              [_vm._v("Funding Source(s)")]
                                            ),
                                            _c("th"),
                                          ]),
                                        ]),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.value.roles,
                                            function (item, i) {
                                              return _c("tr", { key: i }, [
                                                _c(
                                                  "td",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.$emit(
                                                          "showAssignRole",
                                                          {
                                                            userId:
                                                              _vm.value.id,
                                                            userEmail:
                                                              _vm.value.email,
                                                            roleId: item.roleId,
                                                            locationIds:
                                                              item.locationIds,
                                                            fundingSourceIds:
                                                              item.fundingSourceIds,
                                                            setupTables:
                                                              item.setupTables,
                                                            settings:
                                                              item.settings,
                                                          }
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.name) +
                                                        " "
                                                    ),
                                                    item.roleId == 3 &&
                                                    item.setupTables
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              color: "primary",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-table "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    item.roleId == 3 &&
                                                    item.settings
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "ml-2",
                                                            attrs: {
                                                              color: "primary",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-cog-outline "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.$emit(
                                                          "showAssignRole",
                                                          {
                                                            userId:
                                                              _vm.value.id,
                                                            userEmail:
                                                              _vm.value.email,
                                                            roleId: item.roleId,
                                                            locationIds:
                                                              item.locationIds,
                                                            fundingSourceIds:
                                                              item.fundingSourceIds,
                                                            setupTables:
                                                              item.setupTables,
                                                            settings:
                                                              item.settings,
                                                          }
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getLocationList(
                                                            item.locationIds
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.$emit(
                                                          "showAssignRole",
                                                          {
                                                            userId:
                                                              _vm.value.id,
                                                            userEmail:
                                                              _vm.value.email,
                                                            roleId: item.roleId,
                                                            locationIds:
                                                              item.locationIds,
                                                            fundingSourceIds:
                                                              item.fundingSourceIds,
                                                            setupTables:
                                                              item.setupTables,
                                                            settings:
                                                              item.settings,
                                                          }
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getFundingSourceList(
                                                            item.fundingSourceIds
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  [
                                                    item.roleId !=
                                                      _vm.roleEnum.APPROVER &&
                                                    _vm.canEditUser
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "red",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.removeRoleFromUser(
                                                                  item.roleId
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-delete "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _vm.canEditUser
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: !_vm.value.id,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("showAssignRole", {
                                        userId: _vm.value.id,
                                        userEmail: _vm.value.email,
                                      })
                                    },
                                  },
                                },
                                [_vm._v(" Assign Role ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-6", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("h4", [_vm._v("Approver Roles")]),
                          _c(
                            "v-container",
                            { staticClass: "px-0" },
                            [
                              _c(
                                "v-expansion-panels",
                                {
                                  model: {
                                    value: _vm.allPanels,
                                    callback: function ($$v) {
                                      _vm.allPanels = $$v
                                    },
                                    expression: "allPanels",
                                  },
                                },
                                _vm._l(
                                  _vm.value.approver,
                                  function (approver, index) {
                                    return _c(
                                      "v-expansion-panel",
                                      { key: index },
                                      [
                                        _c("v-expansion-panel-header", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.approvalLevelsById[
                                                  approver.approvalLevelId
                                                ]
                                                  ? _vm.approvalLevelsById[
                                                      approver.approvalLevelId
                                                    ].name
                                                  : "New Approver Role"
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c(
                                          "v-expansion-panel-content",
                                          [
                                            _c("approver-creator", {
                                              attrs: {
                                                value: approver,
                                                userId: _vm.value.id,
                                                approvalLevels:
                                                  _vm.approvalLevels,
                                                tripTypes: _vm.tripTypes,
                                                approverIndex: index,
                                                removeApprover:
                                                  _vm.removeApprover,
                                                readonly:
                                                  (!_vm.me.is.superAdmin &&
                                                    !_vm.me.is
                                                      .transportationAdmin) ||
                                                  approver.cannotEdit,
                                                refresh: _vm.refresh,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                              _vm.canEditUser
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "mt-6",
                                      attrs: {
                                        color: "primary",
                                        disabled: !_vm.value.id,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addApprover()
                                        },
                                      },
                                    },
                                    [_vm._v(" Add Approver Role ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("change-password", { ref: "changePassword" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }