<template>
  <div>
    <setup-table
      title="User"
      :items="items"
      :headers="headers"
      :loading="loading"
      :clearItem="!showForm || !editedItem.id"
      showSearch
      @createItem="createItem()"
      @editItem="editItem"
      hideRowSelect
      hide-menu
      pagination
    >
      <template #itemForm v-if="showForm">
        <user-form
          ref="userForm"
          :value="editedItem"
          :roles="roles"
          :approvalLevels="approvalLevels"
          :tripTypes="tripTypes"
          :approvalLevelsById="approvalLevelsById"
          :refreshUser="refresh"
          @showAssignRole="showAssignRole"
          @submit="saveItem"
          @remove="removeItem"
          @closeForm="closeForm"
          @formChanges="formChanges"
        ></user-form>
      </template>
      <!-- <template #[`item.lastLoginTime`]="{ item }">
      <p class="mb-0">{{ format(fromUnixTime(item.lastLoginTime), 'Pp') }}</p>
    </template> -->
    </setup-table>

    <assign-role ref="assignRoleModal" :value="activeRole" @refresh="refresh"></assign-role>
    <location-manager ref="slaManager" @refresh="refresh"></location-manager>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { format, fromUnixTime } from 'date-fns';
import { GET_USERS, GET_ROLES, SAVE_USER, REMOVE_USER } from '@/store/modules/User/actions';
import SetupTable from './SetupTable.vue';
import UserForm from './UserForm.vue';
import AssignRole from './AssignRole.vue';
import LocationManager from './LocationManager.vue';
import promptBeforeLeave from '@/mixins/promptBeforeLeave';
import { cloneDeep } from 'lodash';

export default {
  name: 'UserSetupTable',
  inject: ['eventHub'],
  components: { SetupTable, UserForm, AssignRole, LocationManager },
  data() {
    return {
      format,
      fromUnixTime,
      showForm: false,
      items: [],
      roles: [],
      editedItem: {},
      loading: true,
      activeRole: {
        userId: 0,
        userEmail: '',
        roleId: 0,
        locations: [],
      },
      headers: [
        { text: 'Name', value: 'displayName' },
        { text: 'Email', value: 'email' },
        { text: 'Roles', value: 'roleList' },
        // { text: 'Last Login', value: 'lastLoginTime' },
      ],
    };
  },
  computed: {
    ...mapGetters('tripType', ['tripTypes']),
    ...mapGetters('user', ['users']),
    ...mapGetters('approvalLevel', ['approvalLevels', 'approvalLevelsById']),
  },
  mounted() {
    this.eventHub.$on('manageLocationSLARequested', () => this.manageLocationSLA());
    this.fetchItems();
  },
  beforeDestroy() {
    this.eventHub.$off('manageLocationSLARequested');
  },
  methods: {
    ...mapActions('user', [GET_USERS, GET_ROLES, SAVE_USER, REMOVE_USER]),
    async fetchItems() {
      await this.refresh();
      const roles = await this.getRoles();
      this.roles = roles.filter((e) => e.global);

      this.rolesById = {};
      roles.forEach((e) => (this.rolesById[e.id] = e));
      this.loading = false;
    },
    async refresh() {
      await this.getUsers();
      this.items = this.users.filter((e) => e.id);
      for (let u of this.items) {
        u.name = u.firstName + ' ' + u.lastName + ' (' + u.displayName + ')';
        u.roleList = this.getUserRoleList(u);
      }
      if (this.editedItem.id) this.editedItem = this.items.find((e) => e.id == this.editedItem.id);
    },
    async createItem() {
      const yes = await this.performPromptBeforeLeave();

      if (!yes) return;

      this.editedItem = { id: 0, displayName: '', firstName: '', lastName: '', email: '', phone: '' };
      this.baseFormValue = {
        id: 0,
        displayName: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        yellowfinId: null,
      };
      this.latestFormValue = cloneDeep(this.baseFormValue);
      this.showForm = true;
    },
    async editItem(item) {
      const yes = await this.performPromptBeforeLeave();

      if (!yes) return;
      this.editedItem = { ...item };
      this.baseFormValue = cloneDeep(this.editedItem);
      this.latestFormValue = cloneDeep(this.editedItem);
      this.showForm = true;
    },
    async saveItem(item) {
      try {
        const r = await this.saveUser({ ...item, localOnly: true });
        if (r && r.id) {
          this.$myalert.success('User saved');
          await this.fetchItems();
          this.editedItem = { ...item, id: r.id };
          this.baseFormValue = cloneDeep(this.editedItem);
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async removeItem(id) {
      try {
        const r = await this.removeUser(id);
        if (r && r.done) {
          this.$myalert.success('User removed');
          this.editedItem = {};
          this.showForm = false;
          await this.fetchItems();
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    closeForm() {
      this.showForm = false;
      this.baseFormValue = null;
      this.latestFormValue = null;
    },
    showAssignRole(userRole) {
      this.activeRole = { ...userRole };
      this.$refs.assignRoleModal.dialog = true;
    },
    manageLocationSLA() {
      this.$refs.slaManager.dialog = true;
    },
    getUserRoleList(user) {
      return user.roles.map((e) => e.name).join(', ');
    },
    formChanges(value) {
      this.latestFormValue = cloneDeep(value);
    },
  },
  mixins: [promptBeforeLeave],
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
</style>
