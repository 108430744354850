var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "1000px",
        "no-click-animation": "",
        persistent: "",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Assign Role to User")]),
          _c(
            "v-card-text",
            { staticClass: "pt-5" },
            [
              _c(
                "v-form",
                { ref: "form", on: { submit: _vm.save } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "mb-0 pb-0",
                          attrs: { cols: "12", md: "12" },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.filteredRoles,
                              "item-text": "name",
                              "item-value": "id",
                              label: "Role",
                              readonly: !!_vm.value.roleId,
                              outlined: "",
                            },
                            model: {
                              value: _vm.$v.form.roleId.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.roleId, "$model", $$v)
                              },
                              expression: "$v.form.roleId.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.needsLocation
                    ? [
                        _c("h5", [_vm._v("Locations")]),
                        _c("ComboBox", {
                          attrs: {
                            "show-select-all": "",
                            multiple: "",
                            label: "Locations",
                            items: _vm.locationItems,
                          },
                          on: { input: _vm.handleLocationChange },
                          model: {
                            value: _vm.selectedLocations,
                            callback: function ($$v) {
                              _vm.selectedLocations = $$v
                            },
                            expression: "selectedLocations",
                          },
                        }),
                      ]
                    : _vm._e(),
                  _vm.needsFundingSource
                    ? [
                        _c("h5", [_vm._v("Funding Sources")]),
                        _c("ComboBox", {
                          attrs: {
                            "show-select-all": "",
                            multiple: "",
                            label: "Funding Sources",
                            items: _vm.fundingSourceItems,
                          },
                          on: { input: _vm.handleFundingSourceChange },
                          model: {
                            value: _vm.selectedFundingSources,
                            callback: function ($$v) {
                              _vm.selectedFundingSources = $$v
                            },
                            expression: "selectedFundingSources",
                          },
                        }),
                      ]
                    : _vm._e(),
                  _vm.$v.form.roleId.$model == 3
                    ? [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "6" } },
                              [
                                _c("v-checkbox", {
                                  staticClass: "mt-0",
                                  attrs: {
                                    label: "Allow access to Setup Tables",
                                    value: _vm.userConfig.setupTables,
                                    "hide-details": "",
                                  },
                                  model: {
                                    value: _vm.userConfig.setupTables,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.userConfig,
                                        "setupTables",
                                        $$v
                                      )
                                    },
                                    expression: "userConfig.setupTables",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "6" } },
                              [
                                _c("v-checkbox", {
                                  staticClass: "mt-0",
                                  attrs: {
                                    label: "Allow access to Settings",
                                    value: _vm.userConfig.settings,
                                    "hide-details": "",
                                  },
                                  model: {
                                    value: _vm.userConfig.settings,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.userConfig, "settings", $$v)
                                    },
                                    expression: "userConfig.settings",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.close },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    color: "success",
                    loading: _vm.saving,
                    disabled: _vm.saving,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(" Assign ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }