<template>
  <v-dialog v-model="dialog" max-width="600px" no-click-animation persistent>
    <v-card>
      <v-card-title>Are you sure you want to change this user's password?</v-card-title>
      <v-card-text class="pt-5">
        <v-form ref="form" @submit="decline">
          <v-row>
            <v-col cols="12" md="6">
              <v-checkbox v-model="autoGeneratePassword" label="Auto-generate password"></v-checkbox>
            </v-col>
            <v-col cols="12" md="12" v-show="!autoGeneratePassword">
              <v-text-field
                v-model="newPassword"
                label="New Password"
                :append-icon="showNewPasswordText ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showNewPasswordText ? 'text' : 'password'"
                @click:append="showNewPasswordText = !showNewPasswordText"
              ></v-text-field>

              <password-strength :password="newPassword"></password-strength>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="close()"> No </v-btn>
        <v-btn
          color="primary"
          @click="resetUserPassword()"
          :loading="saving"
          :disabled="saving || (!autoGeneratePassword && newPassword.length < 8)"
        >
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { RESET_PASSWORD } from '@/store/modules/User/actions';
import PasswordStrength from '@/components/PasswordStrength.vue';

export default {
  name: 'ChangePassword',
  components: { PasswordStrength },
  data() {
    return {
      dialog: false,
      saving: false,
      user: {},
      autoGeneratePassword: true,
      newPassword: '',
      showNewPasswordText: false,
    };
  },
  computed: {},
  methods: {
    ...mapActions('user', [RESET_PASSWORD]),
    async resetUserPassword() {
      this.saving = true;
      try {
        const r = await this.resetPassword({ ...this.user, newPassword: this.newPassword });
        if (r && r.done) this.$myalert.success("User's password has been reset & they have been notified");
        else this.$myalert.error("Unable to reset user's password");
      } catch (error) {
        this.$myalert.error(error.message);
      }
      this.close();
      this.saving = false;
    },
    close() {
      this.dialog = false;
      this.user = {};
      this.autoGeneratePassword = true;
      this.newPassword = '';
      this.showNewPasswordText = false;
    },
  },
};
</script>
